import "./navbar.css";
import React from "react";
import NavBarMobile from "./mobile/nav-mobile";
import { useState, useEffect } from "react";
import NavBarDesktop from "./desktop/nav-desktop";

function Navbar() {
  /* Gestion de la largeur d'écran pour afficher le menu Ordi ou Mobile */
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav className="container-nav">
        {windowWidth < 900 ? <NavBarMobile /> : <NavBarDesktop />}
      </nav>
    </>
  );
}
export default Navbar;
