import React from 'react';
import './switchCheckbox.css'; 

function SwitchCheckbox({ IsChecked, SetIsChecked, label}) {
  
    const handleCheckboxChange = (e) => {
        SetIsChecked(e.target.checked);
  };

  return (
    <div className="box-check-add-btn">
      <div className="toggle-pill-color">
        <span className="label-switchCheckbox">
          {label}
        </span>
        <input
          type="checkbox"
          id="pill3"
          name="check"
          checked={IsChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="pill3"></label>
      </div>
    </div>
  );
}

export default SwitchCheckbox;
