import React, { useState } from "react";
import "./secret_santa.css";
import Participants from "./Participants";
import { drawNames } from "./services";
import BackButton from "../../Composant/Back_Button/back_button.jsx";
import SwitchCheckbox from "../../Composant/Switch/switchCheckbox.jsx";
import Snow from "./snow.jsx";

import MediaRessources from "../../ressources/media-ressources.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

function FormulaireParticipants() {
  const [participants, setParticipants] = useState("");
  const [conjoint, setConjoint] = useState("");
  const [includeConjoint, setIncludeConjoint] = useState(false);

  const [participantsList, setParticipantsList] = useState([]);
  let [tirageList, setTirageList] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const handleAddParticipant = () => {
    if (participants) {
      if (includeConjoint && conjoint) {
        setParticipantsList([
          ...participantsList,
          new Participants(participants, conjoint),
          new Participants(conjoint, participants),
        ]);
      } else {
        setParticipantsList([
          ...participantsList,
          new Participants(participants),
        ]);
      }
      setParticipants("");
      setConjoint("");
    }
  };

  const handleButtonClick = () => {
    setTirageList(drawNames(participantsList));
    setIsVisible(true);
  };

  return (
    <>
      <section className="section_secret_santa">
        <div className="box-back-btn-title">
          <BackButton />
          <div className="title_santa">
            <h2>Secret Santa 🎅</h2>
          </div>
        </div>

        <div className="box-text">
          <h2>Fonctionnement du tirage au sort :</h2>
          <p>
            Le tirage se fait aléatoirement en prenant en compte que le
            participant ne peux se tirer lui-même ni son conjoint s'il ou elle
            en a.
            <br />
            Une fois la liste des participants faites, cliqué sur le bouton
            "Tirage" une liste va s'afficher.
          </p>
        </div>

        <div className="boxs-form-lists">
          <div className="box-all-input">
            <div className="inputs-part-and-conj">
              <div className="input-part">
                <label>Participants :</label>
                <input
                  type="text"
                  value={participants}
                  onChange={(e) => setParticipants(e.target.value)}
                />
              </div>

              <div className="input-part">
                <label>Son/Sa Conjoint(e) :</label>
                <input
                  type="text"
                  value={conjoint}
                  onChange={(e) => setConjoint(e.target.value)}
                />
              </div>
            </div>
            <SwitchCheckbox
              IsChecked={includeConjoint}
              SetIsChecked={setIncludeConjoint}
              label={"Inclure le conjoint comme participant ?"}
            />
            <div className="box-img-btns">
              <img
                className="img-santa animate__animated animate__fadeInTopLeft"
                alt=""
                src={MediaRessources.pere_noel}
              />

              <div className="box-btns">
                <button
                  className="btn-add-part"
                  onClick={handleAddParticipant}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Ajouter
                </button>

                <button
                  className="button-4 btn-tirage"
                  onClick={handleButtonClick}
                >
                  <FontAwesomeIcon icon={faWandMagicSparkles} />
                  Tirage Secret Santa
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="boxs-lists-santa">
          <div className="box-list-santa">
            <h3>Listes des participants :</h3>
            <ol>
              {participantsList.map((item, index) => (
                <li key={index}>
                  <span style={{ color: "red" }}>{item.nomPrenom}</span>
                  {item.conjoint && (
                    <span> - (conjoint : {item.conjoint})</span>
                  )}
                </li>
              ))}
            </ol>
          </div>

          {isVisible && (
            <div className="box-list-santa">
              <h3>Listes des tirages au sort :</h3>
              <ol>
                {tirageList.map((item, index) => (
                  <li key={index}>
                    <span style={{ color: "red" }}>{item.giver} </span>
                    doit offrir un cadeau à
                    <span style={{ color: "green" }}> {item.receiver}</span>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>

        <Snow />
      </section>
    </>
  );
}

export default FormulaireParticipants;
