import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import Base from "./Base";
import loisirs from "./Section/2- A propos/loisirs/loisirs";
import portfolioDetail from "./Section/5- Portfolio/portfolio_detail";
import secret_santa from "./Projets/Secret Santa/secret_santa"
import QRCodeGenerator from "./Projets/QrCode/qrCode";
import MentionsLegales from "./Section/Mentions Légales/mention_legales";

import ExperienceTimeline from "./Section/Experiences/experiences";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" Component={Base} />
          <Route path="/experiences" Component={ExperienceTimeline} />
          <Route path="/loisirs" Component={loisirs} />
          <Route path="/portfolio/:id" Component={portfolioDetail} />
          <Route path="/secret-santa" Component={secret_santa} />
          <Route path="/qr-code" Component={QRCodeGenerator} />
          <Route path="/mentions-legales" Component={MentionsLegales} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}
