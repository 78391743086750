import "./card_loisirs.css";
import MediaRessources from "../../ressources/media-ressources";

function Card_Loisirs({ imageUrl, altImg, titleCard, textCard }) {
  return (
    <div className="card-loisirs2">
      <div className="card-loisirs2-img">
        <figure className="figure-card-loisirs2">
          <img src={imageUrl} alt={altImg} className="card-image" />
        </figure>
      </div>
      <div className="article-body">
        <div className="box-title-card-loisirs2">
          <h2 className="title-card-loisirs2">{titleCard}</h2>
          <img
            className="trait-loisirs"
            src={MediaRessources.traitService}
            alt="Trait de séparation entre le titre et les textes des services proposés"
          />
        </div>

        <div
          className="content-card-loisirs2"
          dangerouslySetInnerHTML={{ __html: textCard }}
        ></div>
      </div>
    </div>
  );
}

export default Card_Loisirs;
