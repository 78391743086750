import "./serviceSection.css";
import TitleSection from "../../Composant/TitleSection/titleSection";
import SERVICE_JSON from "./service.json";
import MediaRessources from "../../ressources/media-ressources";

function ServiceSection() {

  function selectVideo(id) {
    const videoMap = {
      1: MediaRessources.video_web,
      2: MediaRessources.video_referencement,
      3: MediaRessources.video_idee,
      4: MediaRessources.video_maintenance,
    };
    return videoMap[id];
  }

  return (
    <section className="section_service" id="section_service">
      <TitleSection title="Services" progress="25"></TitleSection>
      <div className="boxs_service">
        {SERVICE_JSON.cards.map((card) => (
          <div className="box_card" key={card.id}>
            <div className="video-container">
              <video autoPlay muted loop preload="none">
              <track default kind="captions"></track>
              <source src={selectVideo(card.id)} type="video/webm" />
                Votre navigateur ne prend pas en charge la lecture de vidéos au
                format webm.
              </video>
            </div>

            <div className="box-service-texte">
              <p className="card_title">{card.titre}</p>
              <img
                className="trait-service"
                src={MediaRessources.traitService}
                alt="Trait de séparation entre le titre et les textes des services proposés"
              />
              <p className="card_text">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ServiceSection;
