import React from "react";
import "./experience.css";

const experiencesData = [
  {
    title: "Expérience 1",
    period: "Janvier 2015 - Décembre 2016",
    location: "Paris, France",
    company: "Nom de l'entreprise 1",
  },
  {
    title: "Expérience 2",
    period: "Février 2017 - Juin 2018",
    location: "Lyon, France",
    company: "Nom de l'entreprise 2",
  },
  // Ajouter les 6 autres expériences ici
];

const ExperienceTimeline = () => {
  return (
    <>
      <div>
        <h1>Expériences professionnelles</h1>
        {experiencesData.map((experience, index) => (
          <div key={index}>
            <div>{experience.title}</div>
            <div>{experience.period}</div>
            <div>{experience.location}</div>
            <div>{experience.company}</div>
          </div>
        ))}
      </div>

      <div className="timeline">
        <div className="outer">
          <div className="card">
            <div className="info">
              <h3 className="title">Title 1</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="info">
              <h3 className="title">Title 2</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="info">
              <h3 className="title">Title 3</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="info">
              <h3 className="title">Title 4</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="info">
              <h3 className="title">Title 5</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperienceTimeline;
