import React from "react";
import "./nav-desktop.css";
import NAVLINKS from "../nav-links.json";
/* Images */
import MediaRessources from "../../../ressources/media-ressources";

const NavBarDesktop = () => {
  return (
    <>
      <div className="menu-desktop">
        <img
          className="logo_website"
          alt="logo du site de CORNILLEAU Anthony"
          src={MediaRessources.logo}
          title="logo du site de CORNILLEAU Anthony"
        ></img>

        <ul className="menu-desktop-links">
          {NAVLINKS.nav_links.map((link) => (
            <li key={link.id} className="li-navbar">
              <a className="link-navbar" href={link.url}>
                {link.text}
              </a>
            </li>
          ))}
        </ul>

        <div className="link-reseaux">
          <a
            className="link-navbar"
            href="https://www.linkedin.com/in/anthony-cornilleau-baa82018b/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              className="logo-navbar-desktop"
              src={MediaRessources.linkedin}
              alt="Logo du site Linkedin - pointant sur le profil de CORNILLEAU Anthony."
              title="Logo du site Linkedin - pointant sur le profil de CORNILLEAU Anthony."
            ></img>
          </a>

          <a
            className="link-navbar"
            href="https://github.com/Antho-cs"
            rel="noreferrer"
            target="_blank"
          >
            <img
              className="logo-navbar-desktop"
              src={MediaRessources.github}
              alt="Logo du site Github - pointant sur le profil de CORNILLEAU Anthony alias Antho-CS."
              title="Logo du site Github - pointant sur le profil de CORNILLEAU Anthony alias Antho-CS."
            ></img>
          </a>
        </div>
      </div>
    </>
  );
};
export default NavBarDesktop;
