import "./titleSection.css";

function TitleSection(props) {
  return (
    <div className="box_title_section">
      <h2 className="title">{props.title}</h2>
      <div className="line_box">
        <div className="line_title"></div>
        <div className={`line_title line_title_up width-${props.progress}`}></div>
      </div>
    </div>
  );
}

export default TitleSection;
