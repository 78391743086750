import "./back_button.css";
import { useNavigate  } from 'react-router-dom';
import MediaRessources from "../../ressources/media-ressources";

/* Voir pour le retour ciblé */
function Back_Button(props) {
  const navigate  = useNavigate ();

  const handleGoBack = () => {
    // Revenir en arrière dans l'historique
    navigate(-1);
  };

  return (
    <>
    <button className="button-backdown" to="/#section_portfolio" onClick={handleGoBack}>
      <img src={MediaRessources.chevronDown} width={30} height={30} alt="Icone de flèche en direction de la gauche pour signifier un retour à la page principal"/>
      Retour
    </button >
    </>);
}

export default Back_Button;
