import React from "react";
import { useState } from "react";
import "./nav-mobile.css";
import MediaRessources from "../../../ressources/media-ressources";
import NAVLINKS from "../nav-links.json";

const NavBarMobile = () => {
  /* Mobile, avec la pop-up */
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  document.body.style.overflow = isOpen ? "hidden" : "visible";

  return (
    <>
      <img
        className="logo_website"
        alt="logo du site de CORNILLEAU Anthony"
        src={MediaRessources.logo}
        title="logo du site de CORNILLEAU Anthony"
      ></img>
      <span className="line"></span>

      <div className="burger-menu">
        {!isOpen && (
          <button onClick={handleClick} className="burger-button" aria-label="Burger menu - pour ouvrir la navbar">
            <img
              className="menu_icon"
              src={MediaRessources.iconeMenuOpen}
              alt="Burger menu - pour ouvrir la navbar"
            ></img>
          </button>
        )}
        {isOpen && (
          <div className="burger-menu-modal" style={{ overflow: "hidden" }}>
            <button onClick={handleClick} className="burger-button-modal">
              <img
                className="menu_icon_cross"
                src={MediaRessources.iconeMenuClose}
                alt=""
              ></img>
            </button>
            <ul className="burger-menu-links">
              {NAVLINKS.nav_links.map((link) => (
                <li key={link.id}>
                  <a
                    className="link-navbar"
                    href={link.url}
                    onClick={handleClick}
                  >
                    {link.text}
                  </a>
                </li>
              ))}
              <div className="box-logo-navbar ">
                <li>
                  <a
                    className="link-navbar"
                    href="https://www.linkedin.com/in/anthony-cornilleau-baa82018b/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      className="logo-navbar"
                      src={MediaRessources.linkedin}
                      alt="Logo du site Linkedin - pointant sur le profil de CORNILLEAU Anthony."
                      title="Logo du site Linkedin - pointant sur le profil de CORNILLEAU Anthony."
                    ></img>
                  </a>
                </li>

                <li>
                  <a
                    className="link-navbar"
                    href="https://github.com/Antho-cs"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      className="logo-navbar"
                      src={MediaRessources.github}
                      alt="Logo du site Github - pointant sur le profil de CORNILLEAU Anthony alias Antho-CS."
                      title="Logo du site Github - pointant sur le profil de CORNILLEAU Anthony alias Antho-CS."
                    ></img>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBarMobile;
