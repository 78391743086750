import "./loisirs.css";
import BackButton from "../../../Composant/Back_Button/back_button";
import MediaRessources from "../../../ressources/media-ressources";
import CardLoisirs from "../../../Composant/Card_loisirs/card_loisirs";

function Loisirs() {
  const data = [
    {
      imageUrl: MediaRessources.Play,
      imageAlt: "Descriptions d'une manette de Playstation sur fond noir",
      titleCard: "Jeux Vidéo",
      textCard:
        "<p class='p-card-loisirs'>Depuis mon enfance, les jeux d'aventure ont été ma passion, m'emportant dans des voyages extraordinaires à travers des mondes fantastiques et mystérieux.</p><p class='p-card-loisirs'>Plonger dans ces univers virtuels, explorer des contrées lointaines, résoudre des énigmes complexes et affronter des défis inattendus.</p><p class='p-card-loisirs'> Chaque jeu est une aventure unique, offrant des moments de joie, de suspense et de découverte.</p>",
    },
    {
      imageUrl: MediaRessources.yourName,
      imageAlt: "Image d'un film d'animation du nom de Your Name",
      titleCard: "Mangas / Animés",
      textCard:
        "<p class='p-card-loisirs'>La fascination pour l'univers des mangas m'a toujours inspiré.</p><p class='p-card-loisirs'> Ces bandes dessinées japonaises offrent des histoires variées, des personnages mémorables et des aventures épiques.</p><p class='p-card-loisirs'> Chaque manga que je lis me transporte dans des mondes imaginaires riches en émotions et en enseignements.</p>",
    },
    {
      imageUrl: MediaRessources.BoxeThai,
      imageAlt: "Image de représentation de la Boxe Thaï, montrant 2 combattant sur un ring.",
      titleCard: "Boxe Thaï",
      textCard:
        "<p class='p-card-loisirs'>La boxe thaïlandaise est bien plus qu'un simple sport pour moi, c'est un mode de vie.</p><p class='p-card-loisirs'> Les combats exigeants, la discipline rigoureuse et l'entraînement physique intensif sont autant de défis que j'embrasse avec passion.</p><p class='p-card-loisirs'> La boxe thaï m'a enseigné la persévérance, la maîtrise de soi et le respect.</p>",
    },
    {
      imageUrl: MediaRessources.kayak,
      imageAlt: "Image d'un homme de dos sur un kayak avec en fond un couché de soleil",
      titleCard: "Canoë Kayak",
      textCard:
        "<p class='p-card-loisirs'>Le canoë-kayak m'offre une évasion totale de la routine quotidienne.</p><p class='p-card-loisirs'>Pagayer à travers des rivières sinueuses ou sur des lacs sereins me connecte à la nature de manière profonde.</p><p class='p-card-loisirs'>L'adrénaline des rapides et la tranquillité des étendues d'eau calmes font de cette activité une aventure apaisante et exaltante à la fois.</p>",
    },
  ];

  return (
    <section className="section_loisirs">
      <BackButton />
      <p className="titre-loisirs">Mes Loisirs</p>

      <div className="card-container">
        {data.map((item, index) => (
          <CardLoisirs key={index} {...item} />
        ))}
      </div>
    </section>
  );
}
export default Loisirs;
