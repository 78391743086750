import "./a_propos.css";
import MediaRessources from "../../ressources/media-ressources";
import TitleSection from "../../Composant/TitleSection/titleSection";
import Button from "../../Composant/Button/button";

function AboutSection() {
  return (
    <section className="section_about" id="section_about">
      <TitleSection title="A Propos" progress="0"></TitleSection>

      <div className="box_body_about">
        <div className="picture_about">
          <img
            src={MediaRessources.image_about}
            alt="Anthony CORNILLEAU - Developpeur Web Fullstack sur Nantes"
            title="Photo de profil d'Anthony CORNILLEAU - Developpeur Web Fullstack"
            className="img_picture_about"
          ></img>
        </div>
        <div>
          <div className="text_about">
            <p>
              Je suis constamment en quête d'apprentissage et de nouvelles
              opportunités pour améliorer mes compétences et relever de nouveaux
              défis. 💪
              <br />
              <br />
              Mon engagement envers la qualité et la satisfaction du client est
              au cœur de mon approche du développement web, et je suis
              enthousiaste à l'idée de collaborer sur des projets passionnants.
              😊
              <br />
              <br />
              Si vous cherchez un développeur web dévoué et passionné, n'hésitez
              pas à me contacter ! 📲
            </p>
          </div>
          <div className="box_button_flex animate__animated animate__shakeY animate__infinite">
            <Button link={"/loisirs"} text={"⚔️ Mes Loisirs ⚔️"}></Button>
            {/*           <Button link={"/experiences"} text={"Expériences"}></Button>*/}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
