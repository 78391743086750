import "./button.css";

function Button(props) {
  return (
    <div className="button">
      <a className="a_link" href={props.link}>
        {props.text}
      </a>
    </div>
  );
}

export default Button;
