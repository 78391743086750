import "./portfolio_detail.css";
import { Navigate, useParams } from "react-router-dom";

import PORTFOLIODETAILSPROJETCS from "./portfolio_projects_details.json";
import BackButton from "../../Composant/Back_Button/back_button";
import MediaRessources from "../../ressources/media-ressources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPlay } from "@fortawesome/free-solid-svg-icons";

function PortfolioDetail() {
  const { id } = useParams();
  const current_project = PORTFOLIODETAILSPROJETCS.portfolio_detail[id];

  if (!current_project) {
    return <Navigate to="/" />;
  }
 // Surcharge l'image du projet
  current_project.image_detail = Object.values(
    MediaRessources.listImagePortfolio
  )[current_project.id];

  const is_link = current_project.lien_externe !== "";
  const is_link_demo = current_project.lien_demo !== "";

  return (
    <>
      <section className="section-detail-portfolio">
        <BackButton />

        <p className="portfolio_detail_title">{current_project.titre}</p>
        <div className="box_portfolio_detail">
          <div className="box_portfolio_detail_texte">
            <p className="portfolio_detail_techno">
              Techno : {current_project.techno}
            </p>

            <div
              className="portfolio_detail_texte"
              dangerouslySetInnerHTML={{ __html: current_project.texte }}
            />
          </div>

          <div className="portfolio_box_detail_image">
            <img
              className="portfolio_detail_image"
              alt={current_project.alt}
              src={current_project.image_detail}
            />
          </div>
        </div>
        <div className="box_portfolio_detail_texte_2">
          <>
            {is_link && (
              <a
                className="a_link"
                href={current_project.lien_externe}
                target="_blank"
                rel="noreferrer"
              >
                Lien <FontAwesomeIcon icon={faLink} />
              </a>
            )}
            {is_link_demo && (
              <a className="a_link" href={current_project.lien_demo}>
                <FontAwesomeIcon icon={faPlay} beatFade /> Demo
              </a>
            )}
          </>
        </div>
      </section>
    </>
  );
}

export default PortfolioDetail;
